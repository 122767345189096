import { Link } from 'react-router-dom';
import './Button.scss';

export default function Button(props) {

    let button;
    const additionalClassNames = (
        (props.cta ? " cta" : "") +
        (props.disabled ? " disabled" : "") +
        (props.loading ? " loading" : "") + 
        (props.center ? " center" : "") +
        (props.iconOnly ? " icon-only": "")
    )

    if (props.submit){

        button = 
            <input 
                type="submit"
                className={additionalClassNames}
                value={props.text}
                {...props}
                />
        ;

    } else if (props.to) {

        button = 
            <Link
                className={"btn" + additionalClassNames}
                {...props}
                >
                {props.text}
            </Link>
        ;

    } else {

        button = 
            <div
                className={"btn" + additionalClassNames}
                {...props}
                >
                {props.text}
            </div>
        ;

    }

    return (

        <div className="button-wrapper">

            {button}

            {props.loading &&
                <div className="button-loader"></div>
            }

            {props.icon &&
                <div className="button-icon">
                    {props.icon}
                </div>
            }

        </div>

    );

}