import './InputVariables.scss';
import { useInputVariables } from '../../contexts/InputVariablesContext';

const shortCodeToSvgMap = {
   "ASSISTANT_MESSAGE": "text",
   "USER_MESSAGE": "text",
   "NOW": "date",
   "NOW_DATE": "date",
   "NOW_TIME": "date",
}

const svgMap = {
   "text": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20v1h-4v-1h.835c.258 0 .405-.178.321-.422l-.473-1.371h-2.231l-.575-1.59h2.295l-1.362-4.077-1.154 3.451-.879-2.498.921-2.493h2.222l3.033 8.516c.111.315.244.484.578.484h.469zm-6-1h1v2h-7v-2h.532c.459 0 .782-.453.633-.887l-.816-2.113h-6.232l-.815 2.113c-.149.434.174.887.633.887h1.065v2h-7v-2h.43c.593 0 1.123-.375 1.32-.935l5.507-15.065h3.952l5.507 15.065c.197.56.69.935 1.284.935zm-10.886-6h4.238l-2.259-6.199-1.979 6.199z"/></svg>,
   "date": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg>,
   "default": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 10.826v2.349c-1.562 0-3 1.312-3 2.857 0 2.181 1.281 5.968-6 5.968v-2.002c4.917 0 3.966-1.6 3.966-3.967 0-2.094 1.211-3.5 2.278-4.031-1.067-.531-2.278-1.438-2.278-3.312 0-2.372.94-4.692-3.966-4.686v-2.002c7.285 0 6 4.506 6 6.688 0 1.544 1.438 2.138 3 2.138zm-19-2.138c0-2.182-1.285-6.688 6-6.688v2.002c-4.906-.007-3.966 2.313-3.966 4.686 0 1.875-1.211 2.781-2.278 3.312 1.067.531 2.278 1.938 2.278 4.031 0 2.367-.951 3.967 3.966 3.967v2.002c-7.281 0-6-3.787-6-5.969 0-1.545-1.438-2.857-3-2.857v-2.349c1.562.001 3-.593 3-2.137z"/></svg>
}

export default function InputVariables (props) {

   const { variables, loading, error } = useInputVariables();

   if (!props.showInputVariables) return <></>;

   return (

      <div 
         className="input-variables block">

         <h3>Variable Store.</h3>
         <p>Select the variable you want to use in your Flow.</p>

         {variables && variables.map((variable, key) => {

            let svg = svgMap.default;
            if (variable.shortcode in shortCodeToSvgMap) {
               svg = svgMap[shortCodeToSvgMap[variable.shortcode]];
            }

            return (
               <div 
                  key={key} 
                  className={"input-variable" + 
                     (props.maxLength && (props.value.length + variable.shortcode.length >= props.maxLength ? " unavailable" : ""))
                  }
                  onClick={() => {
                     props.setValue(props.value + "{{" + variable.shortcode + "}}")
                     props.setShowInputVariables(false);
                  }}
                  >
                  <div className="text">
                     <h4>{"{{"}{variable.shortcode}{"}}"}</h4>
                     <p>{variable.description}</p>
                  </div>
                  <span className="icon">
                     {svg}
                  </span>
               </div>
            );
         })}

         <div 
            className="input-variable-close"
            onClick={() => {
               props.setShowInputVariables(false);
            }
            }
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
         </div>

      </div>

   );

}