import { useEffect, useRef, useState } from "react"

import './TextInputList.scss';
import TextInput from "./TextInput";

export default function TextInputList(props) {

   const [defaultInputValue, setDefaultInputValue] = useState(null);
   const [value, setValue] = useState([]);
   const [error, setError] = useState(null);

   const inputRef = useRef(null);

   useEffect(() => {
      if (props.defaultValueControlled) {
         setValue(props.defaultValueControlled.split(","));
      }
   }, [props.defaultValueControlled]);

   const addItem = () => {

      if (!inputRef.current.value) return;

      if (props.validateRegex && !props.validateRegex.test(inputRef.current.value)) {
         setError(props.validateError);
         return;
      } else if (value.includes(inputRef.current.value)) {
         setError("This value already exists.");
         return;
      }

      const newValue = [...value];
      newValue.push(inputRef.current.value);
      setValue(newValue);
      setDefaultInputValue(" ");

   }

   return (
      <div className="text-input-list">

         <TextInput
            id={props.id}
            innerRef={inputRef}
            onKeyDown={(e) => {
               if (["Tab", "Enter"].includes(e.key)) {
                  
                  e.preventDefault();
                  addItem();
                  
               } else {
                  setError(null);
                  setDefaultInputValue(null);
               }
            }}
            innerClassName={props.innerClassName}
            example={props.example}
            maxLength={props.maxLengthPerItem}
            blurCallback={() => addItem()}
            defaultValueControlled={defaultInputValue}
            placeholder={props.placeholder}
            validateRegex={props.validateRegex}
            validateError={props.validateError}
            validate={props.validate}
            error={props.error || error}
            info={props.info}
            readOnly={props.maxNumItems && value.length >= props.maxNumItems}
            />

         <input type="hidden" value={value.join(",")} name={props.name} />

         {value.length > 0 && 
         
            <ul>

               {value.map((item, key) => {

                  return (

                     <li 
                        key={key}
                        onClick={() => {
                           setValue(value.filter(valueItem => valueItem !== item));
                        }}   
                     >
                        {item}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                     </li>

                  )

               })}

            </ul>
         
         }

      </div>
   )

}