import { Route, Router, Routes, useLocation, useNavigate } from 'react-router';

import ChatPage from './pages/ChatPage';
import Nav from './components/Nav';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import OverviewPage from './pages/OverviewPage';
import AssistantPage from './pages/AssistantPage';
import IntegrationsOverviewPage from './pages/IntegrationsOverviewPage';
import MetricsPage from './pages/MetricsPage';
import AccountPage from './pages/AccountPage';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationContainer from './components/NotificationContainer';
import useViewportHeight from './hooks/useViewportHeight';
import { useEffect } from 'react';
import { setNavigate } from './common/api';
import SignupPage from './pages/SignupPage';

import PlanPage from './pages/PlanPage';
import IndexPage from './pages/IndexPage';
import IntegrationPage from './pages/IntegrationPage';
import { InputVariablesProvider } from './contexts/InputVariablesContext';


function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const hideNav = location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/logout');

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useViewportHeight();

  return (

    <>
    
    <NotificationProvider>
      <AuthProvider>
        <InputVariablesProvider>

          <Nav 
            hide={hideNav}
            />

            <main
              className={hideNav ? "full-width" : ""}
              >

              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/assistants" element={<OverviewPage />} />
                <Route path="/indices" element={<OverviewPage />} />
                <Route path="/files" element={<OverviewPage />} />
                <Route path="/indices/new" element={<IndexPage new />} />
                <Route path="/indices/:index_id/edit" element={<IndexPage />} />
                <Route path="/assistants/:assistant_id" element={<AssistantPage />} />
                <Route path="/assistants/new" element={<AssistantPage new />} />
                <Route path="/assistants/:assistant_id/chats" element={<ChatPage />} />
                <Route path="/assistants/:assistant_id/chats/:chat_id" element={<ChatPage />} />
                <Route path="/integrations" element={<IntegrationsOverviewPage />} />
                <Route path="/integrations/:integration_id" element={<IntegrationPage />} />
                <Route path="/plans" element={<PlanPage />} />
                <Route path="/metrics" element={<MetricsPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/account/*" element={<AccountPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/logout" element={<LoginPage />} />
              </Routes>

              <NotificationContainer />

            </main>

        </InputVariablesProvider>
      </AuthProvider>
    </NotificationProvider>
    
    </>

  );
}

export default App;
