import { useEffect, useState } from 'react';
import PageTitle from '../components/PageTitle';

import './MetricsPage.scss';
import { SingleSelect } from '../components/inputs/Select';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// charts
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import api from '../common/api';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.defaults.font.family = 'Nunito, sans-serif';


export default function MetricsPage() {

    // navigate
    const navigate = useNavigate();

    // state variables
    const [loading, setLoading] = useState(true);
    const [timeframe, setTimeframe] = useState("this_month");
    const [groupby, setGroupby] = useState("day");
    const [overallMetrics, setOverallMetrics] = useState({});
    const [groupedNumMessages, setGroupedNumMessages] = useState([]);
    const [groupedSumCredits, setGroupedSumCredits] = useState([]);
    const [groupedLabels, setGroupedLabels] = useState([]);

    const [search, setSearch] = useSearchParams();

    const paramStart = search.get("start");
    const paramEnd = search.get("end");
    const paramGroupBy = search.get("groupby")

    useEffect(() => {

        setLoading(true);
        const metricsURL = '/metrics?' + new URLSearchParams(Object.fromEntries([...search])).toString();
        api.get(metricsURL).then(response => {
            
            const newMetrics = response.data.data.metrics;
            setOverallMetrics(newMetrics.overall);
            setGroupedNumMessages(newMetrics.grouped.map((row) => row.num_messages));
            setGroupedSumCredits(newMetrics.grouped.map((row) => row.sum_credits ? row.sum_credits : 0));
            setGroupedLabels(newMetrics.grouped.map((row) => row.date))
            setLoading(false);

        });

    }, [paramStart, paramEnd, paramGroupBy]);

    useEffect(() => {

        function formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        
        function getFirstDayOfMonth(year, month) {
            return new Date(year, month, 1);
        }
        
        function getLastDayOfMonth(year, month) {
            return new Date(year, month + 1, 0);
        }
        
        function getRange(type, period) {
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth();
            let startMonth, endMonth;
        
            if (period === 'last') {
                if (type === 'month') {
                    month -= 1;
                    if (month < 0) {
                        month = 11;
                        year -= 1;
                    }
                    startMonth = month;
                    endMonth = month;
                } else if (type === 'quarter') {
                    startMonth = month - (month % 3) - 3;
                    if (startMonth < 0) {
                        startMonth += 12;
                        year -= 1;
                    }
                    endMonth = startMonth + 2;
                } else if (type === 'year') {
                    year -= 1;
                    startMonth = 0;
                    endMonth = 11;
                }
            } else if (period === 'this') {
                if (type === 'month') {
                    startMonth = month;
                    endMonth = month;
                } else if (type === 'quarter') {
                    startMonth = month - (month % 3);
                    endMonth = startMonth + 2;
                } else if (type === 'year') {
                    startMonth = 0;
                    endMonth = 11;
                }
            }
        
            let start = getFirstDayOfMonth(year, startMonth);
            let end = getLastDayOfMonth(year, endMonth);
        
            return { start: formatDate(start), end: formatDate(end) };
        }
        

        let dates;
        if (timeframe === "alltime") {
            dates = {}
        } else {
            const timeframeComponents = timeframe.split("_");
            dates = getRange(timeframeComponents[1], timeframeComponents[0]);
        }

        if (groupby) {
            dates.groupby = groupby;
        }

        navigate('/metrics?' + new URLSearchParams(dates).toString());

    }, [timeframe, groupby]);
 
    return (
        <div 
            id="metrics"
            >

            <PageTitle 
                title="Metrics."
                />

            <div 
                id="metrics-wrapper"
                className="container"
                >

                <div id="metrics-fields-wrapper">

                    <div id="metrics-fields">

                        <SingleSelect 
                            id="metric-timeframe"
                            placeholder="Timeframe" 
                            name="timeframe"
                            searchable={false}
                            choices={[
                                {value: "alltime", showValue: "All-time"},
                                {value: "this_month", showValue: "This month"},
                                {value: "last_month", showValue: "Last month"},
                                {value: "this_quarter", showValue: "This quarter"},
                                {value: "last_quarter", showValue: "Last quarter"},
                                {value: "this_year", showValue: "This year"},
                                {value: "last_year", showValue: "Last year"},
                            ]}
                            changeCallback={(value) => {
                                setTimeframe(value);
                            }}
                            defaultValueControlled={"this_month"}
                            noWhiteBackground
                            />

                        <SingleSelect 
                            id="metric-groupby"
                            placeholder="Group by" 
                            name="groupby"
                            searchable={false}
                            choices={[
                                {value: "month", showValue: "Month"},
                                {value: "week", showValue: "Week"},
                                {value: "day", showValue: "Day"}
                            ]}
                            changeCallback={(value) => {
                                setGroupby(value);
                            }}
                            defaultValueControlled={"day"}
                            noWhiteBackground
                            />

                    </div>

                </div>

                <div className="metrics-row">
                    
                    <div className="metric block">        
                        {!loading && <span>{overallMetrics.num_messages}</span>}
                        {loading && <div className="loader"></div>}
                        <h2>Number of messages</h2>
                    </div>

                    <div className="metric block">        
                        {!loading && <span>{(overallMetrics.sum_credits ? overallMetrics.sum_credits : 0)}</span>}
                        {loading && <div className="loader"></div>}
                        <h2>Number of qredits</h2>
                    </div>

                </div>

                <div className="chart-row">
                    
                    <div className="chart-wrapper block">

                        <div className="chart-legend">
                            <span>
                                <div style={{backgroundColor: "#5e5cdb"}}></div>
                                Messages
                            </span>
                            <span>
                                <div style={{backgroundColor: "#c7c6eb"}}></div>
                                Qredits
                            </span>
                        </div>
                        
                        <div className="chart">
                                
                            {groupedNumMessages && groupedLabels &&
                                <Bar 
                                    data={{
                                        labels: groupedLabels,
                                        datasets: [{
                                            label: 'Messages',
                                            yAxisID: 'A',
                                            xAxisID: 'x',
                                            data: groupedNumMessages,
                                            backgroundColor: '#5e5cdb',
                                            hoverBackgroundColor: '#5e5cdb'
                                        },
                                        {
                                            label: 'Qredits',
                                            yAxisID: 'B',
                                            xAxisID: 'x',
                                            data: groupedSumCredits,
                                            backgroundColor: '#c7c6eb',
                                            hoverBackgroundColor: '#c7c6eb'
                                        }
                                        ]
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            chartAreaBorder: {
                                                borderColor: 'rgb(150, 150, 150)',
                                            },
                                            tooltip: {
                                                padding: 10,
                                                backgroundColor: "white",
                                                caretSize: 0,
                                                titleColor: 'rgb(100, 100, 100)',
                                                bodyColor: 'rgb(100, 100, 100)',
                                                displayColors: false,
                                                bodyqBud: 'center',
                                                titleqBud: 'center',
                                                cornerRadius: 0,
                                                borderColor: 'rgb(210, 210, 210)'
                                            }
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false
                                                },
                                                ticks: {
                                                    font: {
                                                        weight: 700,
                                                        size: 13,
                                                    }
                                                }
                                            },
                                            'A': {
                                                type: 'linear',
                                                position: 'left',
                                                grid: {
                                                    display: false,
                                                },
                                                ticks: {
                                                    callback: function(val, index) {
                                                        return index % 2 === 0 ? this.getLabelForValue(val) : '';
                                                    },
                                                    padding: 0,
                                                    font: {
                                                        weight: 700,
                                                        size: 13,
                                                    }
                                                },
                                                beginAtZero: true
                                            },
                                            'B': {
                                                type: 'linear',
                                                position: 'right',
                                                ticks: {
                                                    callback: function(val, index) {
                                                        return index % 2 === 0 ? this.getLabelForValue(val) : '';
                                                    },
                                                    padding: 0,
                                                    font: {
                                                        weight: 700,
                                                        size: 13,
                                                    }
                                                },
                                                beginAtZero: true
                                            }
                                        },
                                    }}
                                />
                            }

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )

}