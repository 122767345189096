import { useEffect, useRef, useState } from 'react';
import './FileInput.scss';

export default function FileInput({maxFiles = 1, maxSizePerFile = 5 * 1024 * 1024, allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'], onChange, ...props}) {

   const [dragging, setDragging] = useState(false);
   const [selectedFiles, setSelectedFiles] = useState([]);
   const fileInputRef = useRef(null);

   const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      processFiles(files);
   };

   const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(true);
   };

   const handleDragLeave = () => {
      setDragging(false);
   }

   const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const files = Array.from(event.dataTransfer.files);
      processFiles(files);
   };

   const handleDelete = (index) => {
      setSelectedFiles(prevFiles => {
        const newFiles = prevFiles.filter((_, i) => i !== index);
        if (onChange) onChange(newFiles, setSelectedFiles);
        return newFiles;
      });
    };

   const processFiles = (files) => {
      const validFiles = files.filter((file, index) => {
         if (index >= maxFiles) return false;
         if (file.size > maxSizePerFile) return false;
         const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
         if (!allowedExtensions.includes(fileExtension)) return false;
         if (selectedFiles.some(existingFile => existingFile.name === file.name)) return false;
         return true;
       });

      setSelectedFiles(prevFiles => {
         const newFiles = [...prevFiles, ...validFiles].slice(0, maxFiles);
         if (onChange) onChange(newFiles, setSelectedFiles);
         return newFiles;
      });
   };

   useEffect(() => {
      if (props.defaultValueControlled === " ") setSelectedFiles([]);
   }, [props.defaultValueControlled]);

   return (
      <div 
         className={"file-input" + (dragging ? " dragging" : "")}
         
         >
         <label 
            htmlFor={props.id}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
         >
          <span>Click to select {maxFiles > 1 ? "files" : "a file"} or drag {maxFiles > 1 ? "them" : "it"} here</span>
          <input
            id={props.id}
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple={maxFiles > 1}
            accept={allowedExtensions.join(',')}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm2 14h-4v-1h4v1zm0 1v1h-4v-1h4zm-4-6h-4l6-6 6 6h-4v3h-4v-3z"/></svg>
        </label>

         {maxFiles > 1 && 
            <p>You can select {maxFiles - selectedFiles.length} more file{maxFiles - selectedFiles.length !== 1 ? "s" : ""}.</p>
         }
        
        {!props.noRenderFiles && selectedFiles.length > 0 && (
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index} className="block">
               {file.name.length <= 25 ? file.name : file.name.substring(0, 22) + '...'}
               <svg className="file-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.568.075c2.202 1.174 5.938 4.883 7.432 6.881-1.286-.9-4.044-1.657-6.091-1.179.222-1.468-.185-4.534-1.341-5.702zm-.824 7.925s1.522-8-3.335-8h-8.409v24h20v-13c0-3.419-5.247-3.745-8.256-3z"/></svg>
               <svg 
                  onClick={() => handleDelete(index)}
                  className="delete-icon" 
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
            </li>
            ))}
          </ul>
        )}

      </div>
   );

}