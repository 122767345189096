export function resizeAndEncodeImage(file, maxWidth, maxHeight, quality) {

   return new Promise((resolve, reject) => {
     const reader = new FileReader();
     reader.onload = function(event) {
       const img = new Image();
       img.onload = function() {
         const canvas = document.createElement('canvas');
         let width = img.width;
         let height = img.height;
 
         if (width > height) {
           if (width > maxWidth) {
             height *= maxWidth / width;
             width = maxWidth;
           }
         } else {
           if (height > maxHeight) {
             width *= maxHeight / height;
             height = maxHeight;
           }
         }
 
         canvas.width = width;
         canvas.height = height;
 
         const ctx = canvas.getContext('2d');
         ctx.drawImage(img, 0, 0, width, height);
 
         const base64Image = canvas.toDataURL('image/jpeg', quality);
         resolve(base64Image);
       };
       img.src = event.target.result;
     };
     reader.onerror = reject;
     reader.readAsDataURL(file);

   });
}