import './AreYouSure.scss';
import React, { useRef, useState } from 'react';
import Button from './inputs/Button';
import api from '../common/api';
import TextInput from './inputs/TextInput';

const AreYouSure = ({ url, children, isOpen, setIsOpen, verySure, successCallback, noButtons }) => {

   const verySureRef = useRef(null);
   const [IAmSureError, setIAmSureError] = useState(null);
   const [loading, setLoading] = useState(false);

   const handleConfirm = async () => {

      if (verySure && verySureRef.current.value !== "i-am-sure") {
         setIAmSureError("If you wish to confirm, please type the confirmation value.")
         return;
      }

      setLoading(true);
      api.post(url).then(() => {

      }).catch(() => {}).finally(() => {
         setIsOpen(false)
         setLoading(false);
         if (successCallback) successCallback();
      });
   };


   const handleCancel = () => {
      setIsOpen(false);
   };

   return (
      <div 
         id="are-you-sure-wrapper"
         className={isOpen ? " open" : ""}
         >
         <div
            id="are-you-sure"
            className={"block"}
         >
            <div>{children}</div>

            {verySure &&
               <>
                  <p>Please type the following to confirm: <span className="i-am-sure">i-am-sure</span></p>
                  <TextInput
                     placeholder="Confirmation message" 
                     innerRef={verySureRef}
                     error={IAmSureError}
                     inputCallback={() => setIAmSureError(null) }
                     />
               </>
            }

            {!noButtons &&
               <div className="buttons">
                  <Button text="Cancel" onClick={handleCancel} cta />
                  <Button text="Confirm" loading={loading} onClick={handleConfirm} />
               </div>
            }

         </div>
      </div>
   );
};

export default AreYouSure;