import { useEffect, useState } from 'react';
import './PlanBuilder.scss';
import api from '../common/api';
import Button from './inputs/Button';
import Toggle from './inputs/Toggle';
import Notice from './Notice';
import { useSearchParams } from 'react-router-dom';

const subscriptionProps = {
   "base": {
      "Free": {
         "usps": ["100 qredits per month", "1 assistant", "Chats are retained at most 7 days"],
      },
      "Pro": {
         "usps": ["5,000 qredits per month", "+1 assistant more than Free", "Chat retention up to 1 year"],
      },
      "Premium": {
         "usps": ["15,000 qredits per month", "+3 assistants more than Pro", "Access to email assistants", "Chat retention up to 1 year"],
      },
   },
   "addons": {
      "Extra Assistant": {
         "description": "1 Extra assistant.",
      },
      "1K qredits": {
         "description": "1,000 additional qredits per month.",
      },
      "Unbranded": {
         "description": "Hide 'Powered by qBud' in widget. Applies to all assistants.",
      }
   }
}

function PlansBuilder(props) {

   const [userPlans, setUserPlans] = useState([]);
   const [activePlanNames, setActivePlanNames] = useState([]);
   const [currentBasePlan, setCurrentBasePlan] = useState(null);

   const [search, setSearch] = useSearchParams();

   useEffect(() => {

      api.get("/user/plans").then(response => {
         
         const newUserPlans = response.data.data.user_plans;
         setUserPlans(newUserPlans);
         setActivePlanNames(newUserPlans.map(subscription => subscription.name));

         let newBasePlan = newUserPlans.filter(subscription => ["Free", "Pro", "Premium", "Excellence"].includes(subscription.name))[0];
         setCurrentBasePlan(newBasePlan);

      });

   }, []);

   const createOrder = () => {

      const body = {
         basket: {
            items: {
               base: props.basket.items.base ? props.planMap[props.basket.items.base].name : null,
               addons: Object.keys(props.basket.items.addons).map(addon => ({ name: props.planMap[addon].name, quantity: props.basket.items.addons[addon] }))
            },
            price_type: props.priceType
         },
      }
      api.post("/user/subscriptions", body).then(response => {
         window.location.href = response.data.data.subscription.checkout_url;
      }).catch(() => {});

   }

   const selectBase = (id) => {
      
      const newBasket = {...props.basket};
      newBasket.items.base = newBasket.items.base === id ? null : id;
      newBasket.baseTotal = recalculateBasketBaseTotal(newBasket);
      props.setBasket(newBasket);

   }

   const addAddon = (id) => {
      
      const newBasket = {...props.basket};
      if (!Object.keys(newBasket.items.addons).includes(id)) {
         newBasket.items.addons[id] = 1;
      } else {
         newBasket.items.addons[id] += 1;
      }
      
      newBasket.addonTotal = recalculateBasketAddonTotal(newBasket);
      newBasket.numAddons++;
      props.setBasket(newBasket);

   }

   const removeAddon = (id) => {

      if (!Object.keys(props.basket.items.addons).includes(id)) return;

      const newBasket = {...props.basket};
      newBasket.items.addons[id] -= 1;
      if (newBasket.items.addons[id] <= 0) {
         delete newBasket.items.addons[id];
      }
      newBasket.addonTotal = recalculateBasketAddonTotal(newBasket);
      newBasket.numAddons--;
      props.setBasket(newBasket);

   }

   const recalculateBasketAddonTotal = (basket) => {

      let total = 0;
      for (const item of Object.keys(basket.items.addons)) {
         total += props.planMap[item].price * basket.items.addons[item] * (props.priceType === "month" ? 1 : 10);
      }
      return total;
      
   }

   const recalculateBasketBaseTotal = (basket) => {
      if (props.basket.items.base) {
         return props.planMap[basket.items.base].price * (props.priceType === "month" ? 1 : 10);
      }
      return 0;

   }

   useEffect(() => {
      const newBasket = {...props.basket};
      newBasket.baseTotal = recalculateBasketBaseTotal(newBasket);
      newBasket.addonTotal = recalculateBasketAddonTotal(newBasket);
      props.setBasket(newBasket);
   }, [props.priceType]);

   
   if (search.get("success") === "true") {

      return (
         <>
            <Notice 
               title="That's done!"
               message="You've successfully completed your payment. Your new subscription is active now."
               href="/assistants"
               linkText="To Assistants"
               check
               />
         </>
      )

   } else if (search.get("success") === "false") {

      return (
         <>
            <Notice 
               title="Ouch. Something went wrong."
               message="It looks like the payment was not completed. Please try again."
               href="/account/plans"
               linkText="Retry"
               warning
               />
         </>
      )

   }

   return (

      <div id="plan-builder">

         <h2>Plan Builder.</h2>
         <p>Configure the plan which suits your use case best.</p>

         <div id="plan-builder-toggle">

            <span>Monthly</span>
            <Toggle 
               onClick={(on) => { props.setPriceType(on ? "year" : "month") }}
               />
            <span>Yearly</span>

         </div>
      

         <div id="plan-builder-bases">

            {Object.keys(props.planMap).length > 0 && props.plans.map((subscription, key) => {
               
               if (subscription.category !== "base") return;

               const isActivePlan = activePlanNames.includes(subscription.name) && !activePlanNames.includes("Excellence");
               const notAvailable = currentBasePlan && currentBasePlan !== "Free" && !isActivePlan;
               const isSelected = props.basket.items.base === subscription.id;

               return (
                  <div 
                     key={key} 
                     className={"plan block" + 
                        (isActivePlan ? " active" : "") +
                        (notAvailable ? " not-available": "") +
                        (props.basket.items.base === subscription.name ? " selected" : "")
                        }>

                     {isActivePlan && 
                        <span className="tag">Current plan</span>
                     }

                     <h3>{subscription.name}</h3>
                     <p>In this plan:</p>

                     <ul>
                        {subscriptionProps.base[subscription.name].usps.map((usp, key) => {
                           return (
                              <li key={key}><span>•</span>{usp}</li>
                           )
                        })}
                     </ul>

                     {!isActivePlan && !notAvailable && subscription.name !== "Free" &&
                        <span className="plan-price">€{props.planMap[subscription.id].price * (props.priceType === "month" ? 1 : 10)}<span>*</span></span>
                     }

                     <Button 
                        text={isSelected ? "Remove" : (notAvailable ? "Not available" : (isActivePlan ? "Active" : "Select"))}
                        cta={!isSelected && !isActivePlan && !notAvailable}
                        onClick={() => selectBase(subscription.id)}
                        disabled={isActivePlan || notAvailable}
                        />

                  </div>
               );

            })}

            <p className="price-notice">*: Prices are per {props.priceType}. VAT is applied at checkout.</p>

         </div>

         <p>Enhance your{(currentBasePlan && currentBasePlan !== "Free") ? " current" : ""} plan with powerful add-ons. You can add more than one of a kind.</p>

         <div 
            id="plan-builder-addons"
            className={(props.basket.baseTotal > 0 || props.basket.addonTotal > 0) ? "overlay-shown": ""}
            >

            {props.plans.map((subscription, key) => {

               if (subscription.category !== "addon") return;

               const unbrandedUnavailable = subscription.name === "Unbranded" && (activePlanNames.includes("Unbranded") || Object.keys(props.basket.items.addons).includes(subscription.id));

               return (
                  <div 
                     key={key} 
                     className={"plan addon block"}
                     >

                     <div className="plan-left">
                        <h3>{subscription.name}</h3>  
                        <p>{subscriptionProps.addons[subscription.name].description}</p>
                        <p className="plan-count"><strong>{props.basket.items.addons[subscription.id] ? props.basket.items.addons[subscription.id] : 0}</strong> selected</p>
                     </div>

                     <span className="plan-price">€{props.planMap[subscription.id].price * (props.priceType === "month" ? 1 : 10)}<span>*</span></span>
                     
                     <div 
                        className="plan-right"
                        >
                        {props.basket.items.addons[subscription.id] && 
                           <a className="minus" onClick={() => removeAddon(subscription.id)}>-</a>
                        }
                        {!unbrandedUnavailable && props.basket.items.addons && <a className="add" onClick={() => addAddon(subscription.id)}>+</a>}
                     </div>

                  </div>
               );

            })}

            <p className="price-notice">*: Prices of add-ons are per month. VAT is applied at checkout.</p>

         </div>

         <div 
            id="plan-builder-basket-overlay"
            className={(props.basket.addonTotal > 0 || props.basket.baseTotal > 0) ? "show" : ""}
            >

            <div className="container">

               <div id="plan-builder-basket-overlay-amount-wrapper">
                  {props.basket.items.base &&
                     <span id="plan-builder-overlay-plan">
                        {`${props.planMap[props.basket.items.base].name} Plan @`}
                        <span className="amount"> €{props.basket.baseTotal} per {props.priceType}<span> excl. VAT</span></span>
                     </span>
                  }
                  {Object.keys(props.basket.items.addons).length > 0 &&
                     <span id="plan-builder-overlay-addons">
                        {`${props.basket.numAddons} add-on${props.basket.numAddons > 1 ? 's' : ''} `} @
                        <span className="amount"> €{props.basket.addonTotal} per {props.priceType}<span> excl. VAT</span></span>
                     </span>
                  }
               </div>

               <Button
                  text="To checkout"
                  onClick={createOrder}
                  disabled={props.basket.items.length === 0}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 16v5l9-10.062-9-9.938v5c-14.069 1.711-19.348 15.107-10.606 16.981-3.804-2.936 3.251-7.441 10.606-6.981z"/></svg>}
                  cta
                  />

            </div>

         </div>

      </div>

   );

}


export default function Plans() {

   const [plans, setPlans] = useState([]);
   const [basket, setBasket] = useState({items: {base: null, addons: {}}, total: 0, numAddons: 0});
   const [priceType, setPriceType] = useState("month");
   const [planMap, setPlanMap] = useState({});

   useEffect(() => {
      
      api.get("/plans").then(response => {
         setPlans(response.data.data.plans);
         const newPlanMap = {};
         for (const subscription of response.data.data.plans) {
            newPlanMap[subscription.id] = subscription;
         }
         setPlanMap(newPlanMap);
      });

   }, []);

   return (
      <PlansBuilder
         plans={plans}
         planMap={planMap}
         basket={basket}
         setBasket={setBasket}
         priceType={priceType}
         setPriceType={setPriceType}
         />
   );

}