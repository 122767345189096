import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';
import api from '../common/api';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

   const [user, setUser] = useState({});
   const location = useLocation();
   const navigate = useNavigate();

   const updateUser = () => {

      if (Object.keys(user).length === 0 || !user.first_name) {

         let newUser = {};
         api.get("/user").then(response => {
            
            newUser = {...response.data.data.user};
            if (!newUser.first_name) navigate('/signup?stage=post')
               
         }).then(() =>
            api.get("/user/credits").then(response => {
               newUser.credits = response.data.data.user.credits;
            })
         ).catch(error => {

         }).finally(() => {
            setUser(newUser);
         })

      }

   }

   useEffect(() => {
      updateUser();
   }, [location.pathname]);

   return (
      <AuthContext.Provider value={{ user }}>
         {children}
      </AuthContext.Provider>
   );

};

function useAuth() {
   return useContext(AuthContext);
}

export { AuthProvider, useAuth };
