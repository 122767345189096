import PageTitle from '../components/PageTitle';
import Plans from '../components/PlanBuilder';
import './PlanPage.scss';

export default function PlanPage() {

   return (

      <div id="plans-wrapper" className="container">

         <PageTitle 
            title="Plans."
            />

         <Plans />

      </div>

   )

}