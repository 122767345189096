import React, { createContext, useContext, useEffect, useState } from 'react';

import api from '../common/api';

export const InputVariablesContext = createContext();

export function InputVariablesProvider({ children }) {
   const [variables, setVariables] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
 
   useEffect(() => {
     api.get("/variables")
       .then(response => {
         setVariables(response.data.data.variables);
       })
       .catch(err => {
         setError(err);
       })
       .finally(() => {
         setLoading(false);
       });
   }, []);
 
   return (
     <InputVariablesContext.Provider value={{ variables, loading, error }}>
       {children}
     </InputVariablesContext.Provider>
   );
 }
 
 export function useInputVariables() {
   return useContext(InputVariablesContext);
 }
 