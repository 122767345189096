import { useEffect, useState } from 'react';
import './Toggle.scss';

export default function Toggle(props) {

   const [on, setOn] = useState(false);

   useEffect(() => {
      setOn(props.defaultOn ? true : false);
   }, [props.defaultOn]);

   return (

      <div className={"toggle" + (on ? " on" : "")}>

         <label
            onClick={() => {
               setOn(!on);
               if (props.onClick) props.onClick(!on);
            }}
            >
            <div></div>
         </label>

         <input type="hidden" name={props.name} value={on ? "yes" : ""} />

      </div>

   )

}