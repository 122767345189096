import { useLocation } from 'react-router';
import './PageTitle.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function PageTitle(props) {

    const location = useLocation();
    const [title, setTitle] = useState(null);

    return (
        <div 
            id="page-title-wrapper"
            >

            {props.links &&
            
                <div id="page-nav-wrapper">

                    <div className="container">

                        <ul id="page-nav">

                            {props.links.map((link, key) => {
                                return (
                                    <li
                                        className={
                                            (location.pathname + location.search === link.to ? "active" : "") +
                                            (link.inactive ? " inactive" : "")
                                        }
                                        key={key}
                                        >
                                        <Link 
                                            key={key}
                                            to={link.to}
                                            onClick={() => setTitle(link.text + ".")}
                                            >
                                            {link.icon}
                                            {link.text}
                                        </Link>
                                    </li>
                                )
                            })}

                        </ul>

                    </div>

                </div>

            }


            <div 
                id="page-title"
                className="container"
                >

                <h1>
                    {props.breadcrumb && props.breadcrumb.map((crumb, key) => {
                        return (<><Link to={crumb.href} key={key}>{crumb.text}</Link><span>/</span></>)
                    })}
                    {title || props.title}
                    {props.stats &&
                        <span className="stats">
                            {props.stats.map((stat, key) => {
                                if (!stat) return; 
                                return (
                                    <span key={key}>{stat.used} / {stat.available} {stat.type}</span>
                                )
                            })}
                        </span>
                    }
                </h1>

            </div>

        </div>
    );

}